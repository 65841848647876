
import {
  computed,
  ComputedRef,
  defineComponent,
  onBeforeMount,
  onMounted,
  ref,
  watch,
} from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import store from "@/store";
import Widget3 from "@/components/widgets/charts/Widget3.vue";

export default defineComponent({
  name: "widget-1",
  props: {
    widgetClasses: String,
  },
  components: {},
  watch: {
    storeModel: [
      {
        handler: "updateChart",
        immediate: true,
        deep: true,
      },
    ],
  },
  methods: {
    updateChart(val, oldVal) {
      this.seriesData[0].data = val;
    },
  },
  setup() {
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const baseColor = getCSSVariableValue("--bs-info");
    const lightColor = getCSSVariableValue("--bs-light-info");
    // TODO MOVE THE STORE IN DASHBOARD.VUE SO THAT THE ONMOUNT IS CALLED WHEN THE DASHBOARD IS OPENED
    const storeModel = computed(
      () => store.state.DashboardModule.listOfNewlyRegisterdStudents
    );
    const data = computed(() => storeModel.value);

    onBeforeMount(() => {
      store.dispatch(Actions.GET_NEW_STUDENTS_LIST);
    });

    const options = {
      chart: {
        fontFamily: "inherit",
        type: "area",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {},
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "solid",
        opacity: 1,
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: 3,
        colors: [baseColor],
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
        crosshairs: {
          position: "front",
          stroke: {
            color: baseColor,
            width: 1,
            dashArray: 3,
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (val) {
            return val + " students";
          },
        },
      },
      colors: [lightColor],
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      markers: {
        strokeColor: baseColor,
        strokeWidth: 3,
      },
    };

    const seriesData = [
      {
        name: "Students",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ];

    return {
      options,
      seriesData,
      storeModel,
    };
  },
});
