import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row gy-5 g-xl-8" }
const _hoisted_2 = { class: "col-lg-4" }
const _hoisted_3 = { class: "col-lg-4" }
const _hoisted_4 = { class: "row gy-5 g-xl-8" }
const _hoisted_5 = { class: "card pt-4 mb-6 mb-xl-9" }
const _hoisted_6 = { class: "card-body pt-15" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SchoolTimeline = _resolveComponent("SchoolTimeline")!
  const _component_ChartsWidget3 = _resolveComponent("ChartsWidget3")!
  const _component_FullCalendar = _resolveComponent("FullCalendar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_SchoolTimeline)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_ChartsWidget3, {
          "widget-classes": "card-xxl-stretch-50 mb-5 mb-xl-8",
          "chart-color": "primary",
          "chart-height": "150"
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(), _createBlock(_component_FullCalendar, {
            class: "demo-app-calendar",
            options: _ctx.calendarOptions,
            key: _ctx.scheduledClasses.length
          }, null, 8, ["options"]))
        ])
      ])
    ])
  ], 64))
}