
import {
  computed,
  ComputedRef,
  defineComponent,
  onBeforeMount,
  onMounted,
} from "vue";
import TableWidget9 from "@/components/widgets/tables/Widget9.vue";
import TableWidget5 from "@/components/widgets/tables/Widget5.vue";
import ListWidget1 from "@/components/widgets/lists/Widget1.vue";
import ListWidget2 from "@/components/widgets/lists/Widget2.vue";
import ChartsWidget3 from "@/components/widgets/charts/Widget3.vue";
import ListWidget4 from "@/components/widgets/lists/Widget4.vue";
import ListWidget5 from "@/components/widgets/lists/Widget5.vue";
import ListWidget6 from "@/components/widgets/lists/Widget6.vue";
import ListWidget7 from "@/components/widgets/lists/Widget7.vue";
import ListWidget8 from "@/components/widgets/lists/Widget8.vue";
import MixedWidget1 from "@/components/widgets/mixed/Widget1.vue";
import MixedWidget2 from "@/components/widgets/mixed/Widget2.vue";
import MixedWidget3 from "@/components/widgets/mixed/Widget3.vue";
import MixedWidget4 from "@/components/widgets/mixed/Widget4.vue";
import MixedWidget5 from "@/components/widgets/mixed/Widget5.vue";
import MixedWidget7 from "@/components/widgets/mixed/Widget7.vue";
import MixedWidget10 from "@/components/widgets/mixed/Widget10.vue";
import StatisticsWidget1 from "@/components/widgets/statsistics/Widget1.vue";
import StatisticsWidget2 from "@/components/widgets/statsistics/Widget2.vue";
import StatisticsWidget3 from "@/components/widgets/statsistics/Widget3.vue";
import StatisticsWidget4 from "@/components/widgets/statsistics/Widget4.vue";
import StatisticsWidget5 from "@/components/widgets/statsistics/Widget5.vue";
import StatisticsWidget6 from "@/components/widgets/statsistics/Widget6.vue";

import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import SchoolTimeline from "@/views/apps/SchoolTimeline.vue";
import FullCalendar, { CalendarOptions } from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import moment from "moment";
import { IScheduledClass } from "@/store/modules/IScheduleClass";
import store from "@/store";
import { IStudent } from "@/store/modules/StudentModule";

export default defineComponent({
  name: "dashboard",
  components: {
    SchoolTimeline,
    ChartsWidget3,
    FullCalendar,
  },
  setup() {
    const todayDate = moment().startOf("day");
    const TODAY = todayDate.format("YYYY-MM-DD");
    const scheduledClasses: ComputedRef<Array<IScheduledClass>> = computed(
      () => {
        return store.state.ScheduledClassesModule.classes;
      }
    );

    onBeforeMount(() => {
      store.dispatch(Actions.GET_SCHEDULED_CLASSES);
    });

    onMounted(() => {
      setCurrentPageTitle("Dashboard");
    });

    const iso8601DurationToMinutes = (duration: any) => {
      return moment.duration(duration).asMinutes();
    };

    const addMinutesToDate = (date, minutes) => {
      const endDate = new Date(date.getTime() + minutes * 60000);
      return endDate;
    };

    const scheduledClassesForCalendar: ComputedRef<Array<any>> = computed(
      () => {
        if (scheduledClasses.value) {
          let scheduledClasseForCalendar = scheduledClasses.value.reduce(
            (accumulator: Array<any>, scheduledClass: IScheduledClass) => {
              let classes: Array<any> = [];
              if (scheduledClasses.value && scheduledClasses.value.length > 0) {
                classes.push({
                  title: `${scheduledClass.session.name}`,
                  description: scheduledClass.session.description,
                  start: scheduledClass.startDateTime,
                  end: addMinutesToDate(
                    new Date(scheduledClass.startDateTime),
                    iso8601DurationToMinutes(scheduledClass.session.duration)
                  ),
                  className: "fc-event-primary",
                });
              }
              return accumulator.concat(classes);
            },
            []
          );

          return scheduledClasseForCalendar;
        } else return [];
      }
    );

    const calendarOptions: ComputedRef<CalendarOptions> = computed(() => {
      return {
        plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
        },

        height: 900,
        contentHeight: 880,
        aspectRatio: 3, // see: https://fullcalendar.io/docs/aspectRatio

        nowIndicator: true,
        now: TODAY + "T09:25:00", // just for demo

        views: {
          dayGridMonth: { buttonText: "month" },
          timeGridWeek: { buttonText: "week" },
          timeGridDay: { buttonText: "day" },
        },

        initialView: "dayGridMonth",
        initialDate: TODAY,

        editable: true,
        dayMaxEvents: true, // allow "more" link when too many events
        navLinks: true,
        events: scheduledClassesForCalendar.value,
      };
    });

    return {
      scheduledClasses,
      calendarOptions,
    };
  },
});
