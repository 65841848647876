
import moment from "moment";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "",
  components: {},
  props: {
    description: String,
    teacher: String,
    startDateTime: String,
    duration: String,
  },
  setup(props) {
    const iso8601DurationToMinutes = (duration: any, type: string) => {
      if (type === "minutes")
        return `${moment.duration(duration).asMinutes()}mins`;
      if (type === "hours") return `${moment.duration(duration).asHours()}h`;

      return duration;
    };

    const formatDate = (date: string | undefined) => {
      if (date) {
        return `${new Date(date).toDateString()} at ${new Date(
          date
        ).toLocaleTimeString()}`;
      } else return date;
    };

    const classDescription = ref(props.description);
    const classTeacher = ref(props.teacher);
    const classStartDateTime = ref(formatDate(props.startDateTime));
    const classDuration = ref(
      iso8601DurationToMinutes(props.duration, "minutes")
    );

    return {
      classDescription,
      classTeacher,
      classStartDateTime,
      classDuration,
    };
  },
});
